/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, ColumnCover, ColumnDiv, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prací prostředek See"}>
        <Column className="css-129gh17 --full --parallax pb--60 pt--60" name={"fkh0ifk950u"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/fe20fb681e5f46b2b2e9e1efb55f1081_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 title-box--invert" style={{"maxWidth":1000}} content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"971e609dv1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"See washing <br>&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l8"} name={"sewkahomuk"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/db6b549b5ea743ec9d200b8d25111f8c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/db6b549b5ea743ec9d200b8d25111f8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/db6b549b5ea743ec9d200b8d25111f8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/db6b549b5ea743ec9d200b8d25111f8c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/b086a65e2da649e2b266e520fe2a6bd4_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/b086a65e2da649e2b266e520fe2a6bd4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/b086a65e2da649e2b266e520fe2a6bd4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/b086a65e2da649e2b266e520fe2a6bd4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/b5caa9a3dd834271984ee6986909fc6e_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/b5caa9a3dd834271984ee6986909fc6e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/b5caa9a3dd834271984ee6986909fc6e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/b5caa9a3dd834271984ee6986909fc6e_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/0af1dc735c944170b3a2d499d05e515d_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/0af1dc735c944170b3a2d499d05e515d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/0af1dc735c944170b3a2d499d05e515d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/0af1dc735c944170b3a2d499d05e515d_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/d73752903f18435c83ed38c9f45d1e8d_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/d73752903f18435c83ed38c9f45d1e8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/d73752903f18435c83ed38c9f45d1e8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/d73752903f18435c83ed38c9f45d1e8d_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/715a19cb2f0f4cc296cdec50440064f0_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/715a19cb2f0f4cc296cdec50440064f0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/715a19cb2f0f4cc296cdec50440064f0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/715a19cb2f0f4cc296cdec50440064f0_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/4f11f71c2dc944de8788883dd711104c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/4f11f71c2dc944de8788883dd711104c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/4f11f71c2dc944de8788883dd711104c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/4f11f71c2dc944de8788883dd711104c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/9c23ebf3c16b4f42b8cf048870aa1cdb_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/9c23ebf3c16b4f42b8cf048870aa1cdb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/9c23ebf3c16b4f42b8cf048870aa1cdb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/9c23ebf3c16b4f42b8cf048870aa1cdb_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l30"} name={"v9gju2xuj5b"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/f2ad7be6cdf741cb996f3378c4da6fad_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/35535/f2ad7be6cdf741cb996f3378c4da6fad_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/f2ad7be6cdf741cb996f3378c4da6fad_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/f2ad7be6cdf741cb996f3378c4da6fad_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/f2ad7be6cdf741cb996f3378c4da6fad_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/f2ad7be6cdf741cb996f3378c4da6fad_s=2000x_.jpg 2000w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/52f1bc8efe114453a3368ac7f7b43c56_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/35535/52f1bc8efe114453a3368ac7f7b43c56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/52f1bc8efe114453a3368ac7f7b43c56_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/52f1bc8efe114453a3368ac7f7b43c56_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/52f1bc8efe114453a3368ac7f7b43c56_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/52f1bc8efe114453a3368ac7f7b43c56_s=2000x_.jpg 2000w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l30"} name={"4eecz6cbsb4"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/116c0e12239e4938ae64803555083ab2_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/35535/116c0e12239e4938ae64803555083ab2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/116c0e12239e4938ae64803555083ab2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/116c0e12239e4938ae64803555083ab2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/116c0e12239e4938ae64803555083ab2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/116c0e12239e4938ae64803555083ab2_s=2000x_.jpg 2000w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/85f626ab185c4f94b9f1a245b4ba2355_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/35535/85f626ab185c4f94b9f1a245b4ba2355_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/85f626ab185c4f94b9f1a245b4ba2355_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/85f626ab185c4f94b9f1a245b4ba2355_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/85f626ab185c4f94b9f1a245b4ba2355_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/85f626ab185c4f94b9f1a245b4ba2355_s=2000x_.jpg 2000w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"qbxb0ijd73s"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5a0eecacd2424abf81c900821b205807.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">1. Vysoká koncentrace: \"Už žádné plýtvání a neefektivní praní! S naším See washing pracím gelem získáte více čistoty s menším množstvím produktu. Připravte se na to, že váš prádelník bude plný svěžího a dokonale čistého prádla po každém praní. Maximální výkon, minimální spotřeba - to je úspora, kterou naše planeta ocení a vaše peněženka také!\"</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5a0eecacd2424abf81c900821b205807.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">2. Krásné vůně: \"Obklopte se vůní, která vydrží! S See washing kapslemi přivádíte do svého domova nejen čistotu, ale i dlouhotrvající, okouzlující aroma. Představte si, jak se každé ráno probouzíte do náruče svěžího prádla, které voní jako zahrada plná květů. S naší inovativní vůní se každý váš den stane nezapomenutelným zážitkem pro všechny vaše smysly.\"</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}